import React from "react"
import { StaticQuery, graphql} from "gatsby"
export default () => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        site {
          siteMetadata {
            home {
              title
              description
            }
          }
        }
      }
    `}
    render={data => (
      <div className="hero-header">
        <div className="headline">{data.site.siteMetadata.home.title}</div>
        <div className="primary-content">
          <p>個人開発や深層学習、強化学習に関して学んだこと、論文、ニュース等を紹介しています。</p>
        </div>
      </div>
    )}
  />
)