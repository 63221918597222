import React from "react"
import Helmet from 'react-helmet';
import { graphql, Link } from 'gatsby'
import kebabCase from "lodash/kebabCase"
import Layout from "../components/layout"
import PostLink from "../components/post-link"
import HeroHeader from "../components/heroHeader"

const IndexPage = ({
  data: {
    site,
    allMarkdownRemark: { edges, group },
  },
}) => {

  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)

  return (
    <Layout>
      <Helmet>
        <title>{site.siteMetadata.title}</title>
        <meta name="description" content={site.siteMetadata.description} />
      </Helmet>
      <div className="grids">
        <HeroHeader/>
        <div className="main-content">
          {Posts}
        </div>
        <div className="side-menu">
          <div className="profile">
            <h4>Profile</h4>
            <img src="/assets/profile.jpg" alt="pfofile" />
            <a href="https://twitter.com/ushiji_12?ref_src=twsrc%5Etfw" className="twitter-follow-button" data-show-count="false">Follow @ushiji_12</a>
            <p>
              こんにちは、うしじ です。<br />
              神奈川県在住の2児のパパです。<br /><br />
              AIに興味があり、以前から勉強しようと思っていたのですが、なかなか継続できず。<br />
              2020年5月から再度プログラミングの勉強をはじめました。<br /><br />
              個人開発やAIについて、自身が学んだことを共有していきたいと思いますので、どうぞよろしくお願いします。<br /><br />
              ご意見等は、<a href="https://twitter.com/ushiji_12">Twitter</a>か、<Link to="/contact">お問い合わせページ</Link>までお願いいたします。           
            </p>
          </div>
          <div className="profile">
            <h4>Tags</h4>

            <ul className="tags-list">
            {group.map(tag => (
              <li key={tag.fieldValue}>
                <Link to={`/tags/${kebabCase(tag.fieldValue)}`}>
                  {tag.fieldValue} ({tag.totalCount})
                </Link>
              </li>
            ))}
            </ul>

          </div>
        </div>        
      </div>
    </Layout>
  )
}


export default IndexPage

export const pageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            thumbnail
            tags
          }
        }
      }
    }
  }
`
